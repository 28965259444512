import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonTranslationKey, ModalService, SharedTermsTranslationKey, StorageWrapper } from '@unifii/library/common';
import { MfaChallengeType, MfaErrorData, MfaStatus, OAuthCredentials, UfError, UfRequestError, ensureUfError, getErrorType, isDictionary, isMfaErrorData, isOAuthWithCode, isOAuthWithMfaDevice, isOAuthWithMfaDeviceSetup, isOAuthWithMfaRecoveryCode, isOAuthWithMfaSms, isOAuthWithPassword, isOAuthWithVirtualMfa, isPasswordChangeRequiredErrorData } from '@unifii/sdk';
import { StatusCodes } from 'http-status-codes';

import { MyAccount, UcClient } from 'client';
import { PREVIEW_FORM_STORAGE_KEY } from 'constant';

import { ContextService } from './context.service';
import { TenantSettingsService } from './tenant-settings.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

    private context = inject(ContextService);
    private client = inject(UcClient);
    private router= inject(Router);
    private tenantSettings = inject(TenantSettingsService);
    private modalService = inject(ModalService);
    private storage = inject(StorageWrapper);
    private translateService = inject(TranslateService);

    async login(info: OAuthCredentials): Promise<void> {
        try {

            let skipMFASetup = false;

            if (isOAuthWithPassword(info)) {
                const response = await this.client.authenticateWithPassword(info);

                if (response.mfa_challenge && response.mfa_accepted_challenges) {
                    throw this.getMfaError(MfaStatus.MfaVerifyRequired, response.mfa_challenge, response.mfa_accepted_challenges);
                }

            } else if (isOAuthWithMfaSms(info)) {
                await this.client.authenticateWithMfaSms(info);
                skipMFASetup = true;
            } else if (isOAuthWithCode(info)) {
                await this.client.authenticateWithCode(info);
            } else if (isOAuthWithMfaRecoveryCode(info)) {
                await this.client.authenticateWithMfaRecoveryCode(info);
            } else if (isOAuthWithVirtualMfa(info)) {
                await this.client.authenticateWithVirtualMfa(info);
            } else if (isOAuthWithMfaDeviceSetup(info)) {
                skipMFASetup = true;
                await this.client.authenticateWithMfaDeviceSetup(info);
            } else if (isOAuthWithMfaDevice(info)) {
                await this.client.authenticateWithMfaDevice(info);
            } else {
                throw new Error('Insufficient login information');
            }

            const account = await this.client.getMyAccount();

            if (!account.roles.length) {
                throw new UfError('At least one role is required');
            }

            this.context.account = account;

            if (account.changePasswordOnNextLogin) {
                throw this.getPasswordChangeError();
            }

            if (!skipMFASetup && await this.isMfaSetupRequired(account)) {
                throw this.getMfaError(MfaStatus.MfaSetupRequired);
            }

        } catch (e) {

            const error = ensureUfError(e);

            if (isMfaErrorData(error.data) || isPasswordChangeRequiredErrorData(error.data)) {
                throw error;
            }

            throw this.getAuthError(error);
        }
    }

    async logout(askConfirmation?: boolean): Promise<boolean> {

        if (askConfirmation && !await this.modalService.openConfirm({ title: 'Logout', cancelLabel: 'No', confirmLabel: 'Yes' })) {
            return false;
        }

        this.context.clear();
        this.client.deAuthenticate();
        this.storage.removeItem(PREVIEW_FORM_STORAGE_KEY);
        void this.router.navigate(['/login']);

        return true;
    }

    /* returns true if user is required to setup MFA */
    async isMfaSetupRequired(account: MyAccount): Promise<boolean> {

        const { isMfaEnforced } = await this.tenantSettings.sync();

        return isMfaEnforced && !account.isMfaOptional && !account.isMfaEnabled;
    }

    private getPasswordChangeError(): UfRequestError {

        const code = StatusCodes.UNAUTHORIZED;

        return new UfRequestError('Password Change Required', getErrorType(code), { passwordChangeRequired: true }, code);
    }

    private getMfaError(mfaStatus: MfaStatus.MfaSetupRequired): UfRequestError;
    private getMfaError(mfaStatus: MfaStatus.MfaVerifyRequired, challenge: `${MfaChallengeType}`, acceptedChallenges: string): UfRequestError;
    private getMfaError(mfaStatus: MfaStatus, challenge?: `${MfaChallengeType}`, acceptedChallenges?: string): UfRequestError {

        const code = StatusCodes.UNAUTHORIZED;

        return new UfRequestError(this.translateService.instant(CommonTranslationKey.MfaRequiredLabel), getErrorType(code), { mfaStatus, challenge, acceptedChallenges } satisfies MfaErrorData, code);
    }

    /* responsible for extracting the message out of the response data rather than returning generic message */
    private getAuthError(error: UfRequestError): UfRequestError {

        if (!isDictionary(error.data)) {
            return error;
        }

        error.message = error.data.error_description || error.data.statusText || this.translateService.instant(SharedTermsTranslationKey.ErrorUnknown);

        return error;

    }

}
