<uf-expander class="uf-box flat">

    <div expanderHeader class="uf-app-bar flat">
        <div class="title primary">Options</div>
        <uf-icon *ngIf="isInvalid" name="error" class="error" />
        <uc-expander-controls *ngIf="options.length" [container]="optionsWrap" />
    </div>

    <div #optionsWrap expanderBody class="pad-sm">

        <uf-message *ngIf="options.length > 0 && dataSource" class="x-small warning" icon="warningSolid">
            <p>Unsupported configuration. Please configure either a Data Source or Options.</p>
        </uf-message>

        <uf-message *ngIf="options.length === 0 && dataSource == null && options.valid" class="x-small warning"
            icon="warningSolid">
            <p>Default has no options - please ensure variations cover all scenarios.</p>
        </uf-message>


        <!-- Options -->
        <uc-drag-list *ngIf="meta.options" [items]="options.controls" canDrop="false" canReorder="true">
            <ng-template [ngForOf]="options.controls | asUfControlsGroups" ngFor let-option let-i="index">

                <uf-expander [dragDisabled]="options.disabled" [isExpanded]="expandeds[i]" dragItem
                    class="uf-box flat draggable gap-sm-bottom">
                    <div expanderHeader dragHandle class="uf-app-bar flat">
                        <div class="title primary">
                            {{ getOptionLabel(option) }}
                        </div>
                        <uf-icon *ngIf="option.invalid" name="error" class="error" />
                        <button *ngIf="!option.disabled && type !== fieldTypes.Bool" (click)="removeOption(i)"
                            type="button" title="Delete" class="uf-action">
                            <uf-icon name="delete" />
                        </button>
                    </div>
                    <div [formGroup]="option" expanderBody class="uf-grid pad">
                        <uf-text [formControlName]="optionKeys.Name" label="Name" placeholder="Option name"
                            class="col-12" />
                        <uf-text *ngIf="type !== fieldTypes.Bool" [formControlName]="optionKeys.Identifier"
                            [maxLength]="identifierMaxLength" label="Identifier" placeholder="Option identifier"
                            class="col-12" />
                        <uf-message *ngIf="showIdentifierWarningLength(option)" class="x-small warning col-12"
                            icon="warningSolid">
                            <p>Identifier is too long</p>
                        </uf-message>
                        <uc-markdown *ngIf="showContent" [formControlName]="optionKeys.Content"
                            [showAlignmentOptions]="showAlignmentOptions" label="Content" class="col-12" />
                    </div>
                </uf-expander>

            </ng-template>

        </uc-drag-list>

        <!-- Options validators error sit on the ArrayControl -->
        <uf-error *ngIf="meta.options" [control]="options" />

        <!-- DataSourceConfig -->
        <div *ngIf="dataSourceConfig && meta.dataSourceConfig">
            <div *ngIf="dataSource" class="uf-box flat">
                <div class="uf-app-bar flat">
                    <div class="title">
                        Data Source
                    </div>
                    <button *ngIf="canEditDataSource" (click)="editDataSource()" title="Edit" type="button"
                        class="uf-action">
                        <uf-icon name="edit" />
                    </button>
                    <button (click)="deleteDataSource()" type="button" title="Delete" class="uf-action">
                        <uf-icon name="delete" />
                    </button>
                </div>
                <uf-checkbox *ngIf="meta.avoidDuplicates && avoidDuplicates" [control]="avoidDuplicates"
                    label="Avoid duplicates" class="gap-sides" />
                <uf-data-display-list class="small-label pad">
                    <dt>Type</dt>
                    <dd>{{ dataSource.type | dataSourceTypeLabel }}</dd>
                    <ng-container *ngIf="dataSourceName">
                        <dt>Name</dt>
                        <dd>{{dataSourceName}}</dd>
                    </ng-container>
                </uf-data-display-list>
                <uf-message *ngIf="dataSourceConfig?.showError" [content]="dataSourceConfig.errors?.message"
                    [showExpander]="false" icon="error" class="error gap-sides gap-bottom" />
            </div>
        </div>

        <div class="row x-small gap-sm-top">
            <div class="right"></div>
            <button
                *ngIf="meta.dataSourceConfig && !dataSource && !dataSourceConfig.disabled &&  (options.value == null || options.length === 0)"
                (click)="editDataSource()" type="button" class="uf-button gap-sm-right">
                Add Data Source
            </button>
            <button *ngIf="!options.disabled && meta.options && type !== fieldTypes.Bool && !dataSource"
                (click)="addOption()" type="button" class="uf-button">
                Add Option
            </button>
        </div>

    </div>
</uf-expander>