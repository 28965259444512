import { DOCUMENT } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { StorageWrapper, WindowWrapper } from '@unifii/library/common';
import { UfError } from '@unifii/sdk';

@Injectable()
export class BrowserGuard {

    private readonly message = `You are attempting to view this website in an <strong>unsupported</strong> browser.<br>
    Please download <a href="https://www.google.com/chrome/">Google Chrome</a> to view this website.`;

    private window = inject(WindowWrapper) as Window;
    private document = inject(DOCUMENT);
    private storage = inject(StorageWrapper);

    // eslint-disable-next-line @typescript-eslint/member-ordering
    allowed = new Promise<void>((resolve, reject) => {

        if (this.storage.DevMode || this.supported()) {
            resolve();
        } else {
            this.appendMessage();
            reject(new UfError('Unsupported browser'));
        }
    });

    private appendMessage() {

        const paragraph = this.document.createElement('p');

        paragraph.setAttribute('style', 'position: absolute; margin: auto; left: 0; right: 0; top: 30px; text-align: center;padding: 16px;');
        paragraph.innerHTML = this.message;
        this.document.getElementsByTagName('body')[0]?.appendChild(paragraph);
    }

    private supported(): boolean {
        // TODO keep an eye on this, as it may be deprecated in the future
        // Vendor is deprecated https://developer.mozilla.org/en-US/docs/Web/API/Navigator/vendor
        const navigatorVendor = this.window.navigator.vendor as string | null;

        return (this.window.navigator.userAgent.includes('Chrome') && navigatorVendor?.includes('Google Inc')) || this.window.navigator.userAgent.includes('Firefox');
    }

}
