"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isPasswordChangeRequiredErrorData = exports.isMfaErrorData = exports.isOAuthWithMfaDeviceSetup = exports.isOAuthWithMfaDevice = exports.isOAuthWithMfaSms = exports.isOAuthWithMfaRecoveryCode = exports.isOAuthWithResetToken = exports.isOAuthWithVirtualMfa = exports.isOAuthWithRefreshToken = exports.isOAuthWithCode = exports.isOAuthWithPassword = void 0;
const functions_1 = require("../functions");
const models_1 = require("../models");
const isOAuthWithPassword = (data) => (0, functions_1.isDictionary)(data) &&
    Object.keys(data).length === 2 &&
    (0, functions_1.isStringNotEmpty)(data.username) &&
    (0, functions_1.isStringNotEmpty)(data.password);
exports.isOAuthWithPassword = isOAuthWithPassword;
const isOAuthWithCode = (data) => (0, functions_1.isDictionary)(data) &&
    [1, 2, 3].includes(Object.keys(data).length) &&
    (0, functions_1.isStringNotEmpty)(data.code) &&
    (0, functions_1.isOptionalType)(data.redirect_uri, functions_1.isString) &&
    (0, functions_1.isOptionalType)(data.provider_id, functions_1.isString);
exports.isOAuthWithCode = isOAuthWithCode;
const isOAuthWithRefreshToken = (data) => (0, functions_1.isDictionary)(data) &&
    Object.keys(data).length === 1 &&
    (0, functions_1.isStringNotEmpty)(data.refresh_token);
exports.isOAuthWithRefreshToken = isOAuthWithRefreshToken;
const isOAuthWithVirtualMfa = (data) => (0, functions_1.isDictionary)(data) &&
    Object.keys(data).length === 1 &&
    (0, functions_1.isStringNotEmpty)(data.mfa_token);
exports.isOAuthWithVirtualMfa = isOAuthWithVirtualMfa;
const isOAuthWithResetToken = (data) => (0, functions_1.isDictionary)(data) &&
    Object.keys(data).length === 1 &&
    (0, functions_1.isStringNotEmpty)(data.reset_token);
exports.isOAuthWithResetToken = isOAuthWithResetToken;
const isOAuthWithMfaRecoveryCode = (data) => (0, functions_1.isDictionary)(data) &&
    Object.keys(data).length === 1 &&
    (0, functions_1.isStringNotEmpty)(data.recovery_code);
exports.isOAuthWithMfaRecoveryCode = isOAuthWithMfaRecoveryCode;
const isOAuthWithMfaSms = (data) => (0, functions_1.isDictionary)(data) &&
    Object.keys(data).length === 2 &&
    (0, functions_1.isStringNotEmpty)(data.code) &&
    (0, functions_1.isStringNotEmpty)(data.challenge);
exports.isOAuthWithMfaSms = isOAuthWithMfaSms;
const isOAuthWithMfaDevice = (data) => (0, functions_1.isDictionary)(data) &&
    Object.keys(data).length === 6 &&
    (0, functions_1.isStringNotEmpty)(data.client_data_json) &&
    (0, functions_1.isStringNotEmpty)(data.id) &&
    (0, functions_1.isStringNotEmpty)(data.raw_id) &&
    (0, functions_1.isStringNotEmpty)(data.type) &&
    (0, functions_1.isStringNotEmpty)(data.authenticator_data) &&
    (0, functions_1.isStringNotEmpty)(data.signature);
exports.isOAuthWithMfaDevice = isOAuthWithMfaDevice;
const isOAuthWithMfaDeviceSetup = (data) => (0, functions_1.isDictionary)(data) &&
    Object.keys(data).length === 6 &&
    (0, functions_1.isStringNotEmpty)(data.challenge_key) &&
    (0, functions_1.isStringNotEmpty)(data.client_data_json) &&
    (0, functions_1.isStringNotEmpty)(data.attestation_object) &&
    (0, functions_1.isStringNotEmpty)(data.id) &&
    (0, functions_1.isStringNotEmpty)(data.raw_id) &&
    (0, functions_1.isStringNotEmpty)(data.type);
exports.isOAuthWithMfaDeviceSetup = isOAuthWithMfaDeviceSetup;
const isMfaErrorData = (data) => (0, functions_1.isDictionary)(data) &&
    (0, functions_1.isValueOfStringEnumType)(models_1.MfaStatus)(data.mfaStatus) &&
    (0, functions_1.isOptionalType)(data.challenge, (0, functions_1.isValueOfStringEnumType)(models_1.MfaChallengeType)) &&
    (0, functions_1.isOptionalType)(data.acceptedChallenges, functions_1.isString);
exports.isMfaErrorData = isMfaErrorData;
const isPasswordChangeRequiredErrorData = (data) => (0, functions_1.isDictionary)(data) &&
    (0, functions_1.isBoolean)(data.passwordChangeRequired);
exports.isPasswordChangeRequiredErrorData = isPasswordChangeRequiredErrorData;
