<div *ngIf="form" [formGroup]="form" class="uf-box flat">
    <uf-expander [dragDisabled]="true" [isExpanded]="true" dragItem>
        <div expanderHeader class="uf-app-bar flat">
            <div class="title">
                {{typeDescription[type]}}
            </div>

            <button title="Delete" type="button" class="uf-action">
                <uf-icon (click)="deleteClick.emit({position: identifier, parent})" name="delete" />
            </button>

        </div>

        <div expanderBody class="uf-grid pad">
            <ng-container [ngSwitch]="type">

                <!-- Role Assigned -->
                <uf-autocomplete *ngSwitchCase="types.RoleAssignment" [formControlName]="controlKeys.Identifier"
                    [allowCustom]="allowCustomRoles" [options]="filteredRoles" (searchChange)="findRoles($event)"
                    placeholder="Select a Role" label="Role" class="col-12" />

                <!-- GroupMembership -->
                <ng-container *ngSwitchCase="types.GroupMembership">
                    <uf-autocomplete *ngIf="groupControl" [formControlName]="controlKeys.Group"
                        [options]="filteredSourceGroups" [allowCustom]="true" (searchChange)="findSourceGroups($event)"
                        (valueChange)="changeGroup(groupControl, $event)" placeholder="Select a source" label="Group"
                        nameProperty="name" class="col-12" />
                </ng-container>

                <!-- ClaimValue -->
                <ng-container *ngSwitchCase="types.ClaimValue">
                    <uf-autocomplete [formControlName]="controlKeys.Identifier" [options]="filteredSourceClaims"
                        [allowCustom]="true" (searchChange)="findSourceClaims($event)" label="Claim"
                        placeholder="Select a source" class="col-6" />
                    <uf-text [formControlName]="controlKeys.Value" label="Claim Value" class="col-6" />
                </ng-container>

                <!-- ClaimFrom -->
                <uf-autocomplete *ngSwitchCase="types.ClaimFrom" [formControlName]="controlKeys.Identifier"
                    [options]="filteredSourceClaims" [allowCustom]="true" (searchChange)="findSourceClaims($event)"
                    label="Claim" placeholder="Select a source" class="col-12" />

                <!-- And / Or -->
                <div *ngSwitchDefault class="col-12">
                    <uc-auth-provider-mapping-unit
                        *ngFor="let childrenMapping of childrenControls.controls | asUfControlsGroups; let i = index"
                        [parent]="form" [form]="childrenMapping" [sourceClaims]="sourceClaims" [identifier]="i"
                        [authProviderId]="authProviderId" [config]="config" (deleteClick)="deleteClick.emit($event)" />
                    <div class="uf-form-actions">
                        <button [options]="conditionOptions" (optionSelected)="addCondition($event)" type="button"
                            class="uf-button x-small" listBox nameProperty="name">
                            Add
                            <uf-icon name="more" class="gap-none" />
                        </button>
                    </div>
                </div>

            </ng-container>
        </div>
    </uf-expander>


</div>