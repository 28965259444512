import { ChangeDetectorRef, Component, Optional } from '@angular/core';
import { ExpressionParser, MessageLevel, UfControl, ValidatorFunctions } from '@unifii/library/common';
import { Dictionary, Field, FieldTemplate, FieldType, HierarchyUnitFormData, HierarchyUnitSelectionMode, Option } from '@unifii/sdk';
import { debounceTime } from 'rxjs/operators';

import { BuilderField } from 'client';
import { BuilderService } from 'components/compound-builder/builder.service';
import { TemplateConfigManager } from 'components/compound-builder/template-config-manager';
import { ArrayHelper, FieldDetailHelper, FieldIdentifierValidCharactersValidator, IdentifierFunctions, IdentifierNoEmptySpacesValidator } from 'helpers/helpers';

import { FieldAttributeConfig, FieldDetailBasic } from './field-detail-basic';

interface FieldSettingsConfig {
    heading: FieldAttributeConfig;
    label: FieldAttributeConfig;
    shortLabel: FieldAttributeConfig;
    identifier: FieldAttributeConfig;
    collection: FieldAttributeConfig;
    types: FieldAttributeConfig;
    helpText: FieldAttributeConfig;
    currency: FieldAttributeConfig;
    placeholder: FieldAttributeConfig;
    step: FieldAttributeConfig;
    format: FieldAttributeConfig;
    autofill: FieldAttributeConfig;
    bindTo: FieldAttributeConfig;
    tags: FieldAttributeConfig;
    isReadOnly: FieldAttributeConfig;
    isRequired: FieldAttributeConfig;
    isOneToMany: FieldAttributeConfig;
    autoDetect: FieldAttributeConfig;
    maxLength: FieldAttributeConfig;
    precision: FieldAttributeConfig;
    translatable: FieldAttributeConfig;
    ceiling: FieldAttributeConfig;
    selectionMode: FieldAttributeConfig;
}

@Component({
    selector: 'uc-field-settings',
    templateUrl: './field-settings.html',
    styleUrls: ['./field-settings.less'],
})
export class FieldSettingsComponent extends FieldDetailBasic {

    protected readonly currencies = [
        { value: 'AUD', name: 'AUD - Australian dollar' },
        { value: 'CAD', name: 'CAD - Canadian dollar' },
        { value: 'HKD', name: 'HKD - Hong Kong dollar' },
        { value: 'NZD', name: 'NZD - New Zealand dollar' },
        { value: 'SGD', name: 'SGD - Singapore dollar' },
        { value: 'USD', name: 'USD - United States dollar' },
    ];

    protected readonly fieldType = FieldType;
    protected config: FieldSettingsConfig;
    protected identifierMaxLength = this.builderService.identifierLimit;
    protected filteredTags: string[] = [];
    protected showAlignmentOptions = false;
    protected showWarningIdentifier = false;
    protected hierarchyCeiling: HierarchyUnitFormData | null;
    protected selectionModeOptions: Option[];

    private readonly debounce = 100;
    private lastIdentifier: string | null;

    constructor(
        public override builderService: BuilderService,
        ref: ChangeDetectorRef,
        private expressionParser: ExpressionParser,
        @Optional() private templateConfigManager?: TemplateConfigManager,
    ) {
        super(builderService, 'settings', ref);
        this.generateControls();
    }

    protected filterTags(query: string | null) {
        this.filteredTags = ArrayHelper.filterList(this.builderService.tags, query ?? undefined);
    }

    protected updateFieldCeiling(unit?: HierarchyUnitFormData) {

        if (!unit) {
            delete this.field.hierarchyConfig?.ceiling;

            return;
        }

        this.field.hierarchyConfig = this.field.hierarchyConfig ?? {};
        this.field.hierarchyConfig.ceiling = unit.id;
    }

    protected setup(field: BuilderField) {
        // Get metadata
        const parent = this.builderService.builder.getFieldPosition(this.field)?.parent as Field | undefined;
        const fm = FieldDetailHelper.getMetadata(field, this.builderService.builder.type, parent);

        // Compute show
        this.config.heading.show = fm.heading;
        this.config.label.show = fm.label;
        this.config.shortLabel.show = fm.shortLabel;
        this.config.identifier.show = fm.identifier && field.name == null;
        this.config.collection.show = fm.collections;
        this.config.types.show = fm.types;
        this.config.helpText.show = fm.help;
        this.config.currency.show = fm.currency;
        this.config.placeholder.show = fm.placeholder && (
            (field.type !== FieldType.MultiChoice &&
                field.type !== FieldType.Choice &&
                field.type !== FieldType.Bool
            ) || field.template === FieldTemplate.DropDown
        );
        this.config.step.show = fm.step;
        this.config.format.show = fm.format;
        this.config.autofill.show = fm.autoFill && field.name == null;
        this.config.bindTo.show = fm.bindTo && field.name == null;
        this.config.tags.show = field.name == null;
        this.config.isReadOnly.show = fm.readOnly && field.name == null;
        this.config.isRequired.show = fm.required && field.name == null;
        this.config.isOneToMany.show = fm.oneToMany && field.name == null;
        this.config.autoDetect.show = fm.autoDetect && field.name == null;
        this.config.maxLength.show = fm.maxLength && field.name == null;
        this.config.precision.show = fm.precision;
        this.config.translatable.show = fm.isTranslatable;
        this.config.ceiling.show = fm.ceiling;
        this.config.selectionMode.show = fm.ceiling;

        // Add/Remove controls from the form
        Object.keys(this.config).forEach((k) => {

            const key = k as keyof FieldSettingsConfig;

            if (this.config[key].show && this.form.controls[k] == null) {
                this.form.addControl(k, this.config[key].control);
            }

            if (!this.config[key].show && this.form.controls[k] != null) {
                this.form.removeControl(k);
            }
        });

        // Identifier
        this.lastIdentifier = field.identifier ?? null;

        this.selectionModeOptions = [{
            identifier: HierarchyUnitSelectionMode.Leaf,
            name: 'Leaf units only',
        }, {
            identifier: HierarchyUnitSelectionMode.Any,
            name: 'Any unit',
        }];

        this.normalizeData(field);
    }

    protected update() {
        this.setup(this.field);
    }

    private normalizeData(field: BuilderField) {
        // Currency default country
        if (this.config.currency.show && !field.currency) {
            field.currency = 'AUD';
        }

        this.showAlignmentOptions = !(this.field && [FieldType.Choice, FieldType.MultiChoice].includes(this.field.type) &&
            (this.field.template && [
                FieldTemplate.OptionWithContent,
                FieldTemplate.RadioWithContent,
                FieldTemplate.CheckboxWithContent,
            ].includes(this.field.template)));

        if (this.field.type === FieldType.Content && this.field.label) {
            this.field.help = '### ' + this.field.label + '\r\n' + (this.field.help ?? '');
            delete this.field.label;
        }

        this.showWarningIdentifier = (this.field.identifier?.length ?? 0) > IdentifierFunctions.WARNING_IDENTIFIER_MAX_LENGTH;
    }

    private generateControls() {

        const config: Dictionary<FieldAttributeConfig> = {};

        // Heading
        config.heading = { show: false, control: new UfControl() };

        // Identifier
        config.identifier = {
            show: false, control: new UfControl(ValidatorFunctions.compose([
                ValidatorFunctions.custom((v) => !this.field || !ValidatorFunctions.isEmpty(v), 'Identifier is required'),
                FieldIdentifierValidCharactersValidator,
                ValidatorFunctions.custom((v) => !IdentifierFunctions.isInConflict(v, this.field, this.builderService.definition.fields), `Identifier needs to be unique`),
                IdentifierNoEmptySpacesValidator,
                ValidatorFunctions.custom((v) => !v || v.length <= this.identifierMaxLength,
                    `Identifier can't be longer than ${this.identifierMaxLength} characters`),
                ValidatorFunctions.custom((v) => !/^toDate$|^add$|^toTime$|^id$|^consoleName$/.test(v),
                    `This is a reserved term in the system`),
            ])),
        };

        // Field edited workflow is triggered and the field is set up again, this update the lastIdentifier
        // Avoid debounce as the identifier replace in the content need to happen before
        this.subscriptions.add(config.identifier.control.valueChanges/* .debounceTime(this.debounce)*/.subscribe((v) => {
            if (this.builderService.compound) {
                if (this.builderService.compound[this.lastIdentifier as string] && !this.builderService.compound[v]) {
                    // move field content and delete previous
                    // console.log('Move content for identifier', this.lastIdentifier, 'to', v);
                    this.builderService.compound[v] = this.builderService.compound[this.lastIdentifier as string];
                    delete this.builderService.compound[this.lastIdentifier as string];
                }
            }
            this.lastIdentifier = v;

            this.showWarningIdentifier = v.length > IdentifierFunctions.WARNING_IDENTIFIER_MAX_LENGTH;

            if (this.builderService.definition.lastPublishedAt && !this.field.isNew) {
                this.builderService.notify.next({
                    level: MessageLevel.Warning,
                    title: 'Warning',
                    message: 'Editing your identifier after your field is published may cause errors with your ' + this.builderService.builder.type,
                });
            }
        }));

        this.subscriptions.add(config.identifier.control.statusChanges.subscribe(() => {
            // Update template manager incase field is a dependency on a template
            this.templateConfigManager?.update();
        }));

        // Label
        config.label = { show: false, control: new UfControl(ValidatorFunctions.required('Label is required')) };

        this.subscriptions.add(config.label.control.valueChanges.pipe(debounceTime(this.debounce)).subscribe((v) => {
            if (this.config.identifier.show && this.field.isNew && (this.field.shortLabel || '').trim().length === 0) {
                const identifier = IdentifierFunctions.safeFieldIdentifier(this.field, this.builderService.definition.fields, v);

                this.config.identifier.control.setValue(identifier);
                this.config.identifier.control.markAsTouched();
            }
        }));

        // Short Label
        config.shortLabel = { show: false, control: new UfControl() };
        this.subscriptions.add(config.shortLabel.control.valueChanges.pipe(debounceTime(this.debounce)).subscribe((v) => {
            if (this.config.identifier.show && this.field.isNew) {
                const identifier = IdentifierFunctions.safeFieldIdentifier(this.field, this.builderService.definition.fields, v);

                this.config.identifier.control.setValue(identifier);
                this.config.identifier.control.markAsTouched();
            }
        }));

        // Collection
        config.collection = { show: false, control: new UfControl() };
        // Types
        config.types = { show: false, control: new UfControl() };
        // Help Text
        config.helpText = { show: false, control: new UfControl() };
        // Currency
        config.currency = { show: false, control: new UfControl(ValidatorFunctions.required('Currency is required.')) };
        // Placeholder
        config.placeholder = { show: false, control: new UfControl() };
        // Step
        config.step = {
            show: false, control: new UfControl(ValidatorFunctions.compose([
                ValidatorFunctions.custom((v) => !(+v === 0 || v == null), 'Interval needs to be greater than zero.'),
                ValidatorFunctions.custom((v) => !(+v > 60), 'Interval needs to be less than 60.'),
                ValidatorFunctions.custom((v) => !(![1, 1, 2, 3, 4, 5, 10, 12, 15, 20, 30, 60].includes(+v)), 'Interval needs to be a valid step.'),
            ])),
        };
        // Format
        config.format = { show: false, control: new UfControl() };
        // Autofill
        config.autofill = { show: false, control: new UfControl() };
        // BindTo
        config.bindTo = {
            show: false, control: new UfControl(ValidatorFunctions.compose([
                ValidatorFunctions.isValidExpression(this.expressionParser, 'Invalid expression'),
                ValidatorFunctions.custom((v) => v == null || this.field.isReadOnly === true, 'bindTo only supported for isReadOnly field'),
            ])),
        };
        // Tags
        config.tags = {
            show: false,
            control: new UfControl([
                ValidatorFunctions.custom((v) => !(v as string[] || []).find((tag) => tag.includes(' ')), 'Tags cannot contain spaces'),
            ]),
        };
        // DataSource
        config.dataSource = {
            show: false, control: new UfControl(ValidatorFunctions.compose([
                ValidatorFunctions.custom((v) => !this.field || this.field.type !== FieldType.Lookup || !ValidatorFunctions.isEmpty(v), 'Data Source is required.'),
                ValidatorFunctions.custom((v) => v == null || typeof v === 'object' || /^\S*$/.test(v), `Can't contain white space`),
            ])),
        };
        // isReadOnly
        config.isReadOnly = { show: false, control: new UfControl() };
        // isRequired
        config.isRequired = { show: false, control: new UfControl() };
        // isOneToMany
        config.isOneToMany = { show: false, control: new UfControl() };
        // autodetect
        config.autoDetect = { show: false, control: new UfControl() };
        // maxLength
        config.maxLength = { show: false, control: new UfControl() };
        // Precision
        config.precision = { show: false, control: new UfControl() };
        // Translatable
        config.translatable = { show: false, control: new UfControl() };
        // hierarchyConfig.ceiling
        config.ceiling = { show: false, control: new UfControl() };
        // hierarchyConfig.selectionMode
        config.selectionMode = { show: false, control: new UfControl() };

        this.subscriptions.add(config.isReadOnly.control.valueChanges.subscribe(() => config.bindTo?.control.updateValueAndValidity()));

        this.config = config as any;
    }

}
