"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BackendToCredentialRequestOptions = exports.BackendToDeviceMfaSetupChallenge = void 0;
const crypto_functions_1 = require("./crypto-functions");
const type_guard_functions_1 = require("./type-guard-functions");
/** Convert challenge and user.id from base64url to ArrayBuffer to confirm to WebAuthn spec */
const BackendToDeviceMfaSetupChallenge = (response) => {
    const challenge = response.publicKey.challenge;
    const userId = response.publicKey.user.id;
    if ((0, type_guard_functions_1.isString)(challenge)) {
        response.publicKey.challenge = (0, crypto_functions_1.base64UrlToArrayBuffer)(challenge);
    }
    if ((0, type_guard_functions_1.isString)(userId)) {
        response.publicKey.user.id = (0, crypto_functions_1.base64UrlToArrayBuffer)(userId);
    }
    return response;
};
exports.BackendToDeviceMfaSetupChallenge = BackendToDeviceMfaSetupChallenge;
/** Convert challenge and credentialId from base64url to ArrayBuffer to confirm to WebAuthn spec */
const BackendToCredentialRequestOptions = (response) => {
    const challenge = response.publicKey.challenge;
    if ((0, type_guard_functions_1.isString)(challenge)) {
        // Convert challenge and user.id from base64url to ArrayBuffer to confirm to WebAuthn spec
        response.publicKey.challenge = (0, crypto_functions_1.base64UrlToArrayBuffer)(challenge);
    }
    // Decode each allowCredential ID
    if (response.publicKey.allowCredentials) {
        response.publicKey.allowCredentials = response.publicKey.allowCredentials.map((cred) => ({
            ...cred,
            id: (0, type_guard_functions_1.isString)(cred.id) ? (0, crypto_functions_1.base64UrlToArrayBuffer)(cred.id) : cred.id,
        }));
    }
    return response;
};
exports.BackendToCredentialRequestOptions = BackendToCredentialRequestOptions;
