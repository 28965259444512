<div class="header-pane uf-app-bar transparent">
    <uf-breadcrumbs [breadcrumbs]="breadcrumbs" class="large">
        <span *ngIf="edited">*</span>
    </uf-breadcrumbs>
    <div class="row space-children right">
        <a [routerLink]="['..']" type="button" class="uf-button tertiary">
            Cancel
        </a>
        <button (click)="save()" type="button" class="uf-button primary small">Save</button>
    </div>
</div>
<uf-panel *ngIf="form" class="content-pane container">
    <div [formGroup]="form" class="uf-container-lg">
        <uc-user-details [form]="form" [lockedConfig]="lockedConfig" [error]="error" />

        <uc-user-manager [form]="form" cssClasses="gap-top" />

        <uc-user-mfa [form]="form" [selfSetup]="true" cssClasses="gap-top" />

        <uc-user-external-authentication [userInfo]="userInfo" [lockedConfig]="lockedConfig"
            [connectToExternal]="connectToExternal" [isMyAccount]="true" [edited]="edited" cssClasses="gap-top" />

        <uc-user-hierarchy [form]="form" [lockedConfig]="lockedConfig" cssClasses="gap-top" />

        <uc-user-system-roles [lockedConfig]="lockedConfig" [form]="form" cssClasses="gap-top" />

        <uc-user-roles [lockedConfig]="lockedConfig" [form]="form" [name]="tenant.name" cssClasses="gap-top" />
    </div>
</uf-panel>