import { Injectable, Type } from '@angular/core';
import { FilterEntry, FilterValue, IFilterComponent, UfFilterComponentRegistry } from '@unifii/library/common';

import { CustomChoiceFilterComponent } from 'components';

@Injectable()
export class MediaFilterRegistry extends UfFilterComponentRegistry {

    override get(entry: FilterEntry): Type<IFilterComponent<FilterValue>> | undefined {
        if (entry.identifier === 'pending') {
            return CustomChoiceFilterComponent as Type<IFilterComponent<FilterValue>>;
        }

        return super.get(entry);
    }

}
